<template>
	<div>
		<v-row v-for="id in answerOptionsOrder" :key="id">
			<v-col>
				<v-checkbox
					class="ml-4"
					:value="answerOptions[id].value"
					v-model="selected"
					:hide-details="true"
					:multiple="multiple"
					:label="answerOptions[id].text"
					:disabled="isDisabled"
				>
				</v-checkbox>
			</v-col>
		</v-row>
	</div>
</template>

<style lang="less">
@import "../../../../src/assets/css/survey/answerOptions.less";
</style>

<script type="text/javascript">
export default {
	name: "Checkboxes",
	props: {
		value: {},
		answerOptionsOrder: {}
	},
	inject: ["question", "multiple", "disabled", "answerOptions"],
	data: () => {
		return {
			selected: [],
		};
	},
	computed: {
		isDisabled(){
			return this.question.disable_input
		}
	},
	watch: {
		selected(v) {
			// if (v) {
				this.$emit("update", v);
			// }
		},
		value: {
			immediate: true,
			handler() {
				this.selected = this.value;
			},
		},
	},
};
//
</script>
"
